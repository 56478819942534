import React from "react";
import NavBar from "../NavBar/NavBar";
import TableViewCell from "../TableView/TableViewCell";
import TableViewHeader from "../TableView/TableViewHeader";
import Localizer from "../Localization/Localizer";
import { CONSULTATION_URL } from "../data/Constants";
import LanguageManager from "../LanguagePicker/LanguageManager";
import { withAuth0 } from "@auth0/auth0-react";
import LoadingView from "../Reusable Views/LoadingView";
import DeleteAccountBox from "./DeleteAccountBox";
import { HIDE_LANGUAGE_SELECT } from "../data/Constants";
let localizations = Localizer.getLocalizations();

class SettingsView extends React.Component {
	state = {
		showDeleteAccountBox: false,
	};

	constructor(props) {
		super(props);

		this.showConsultationPage = this.showConsultationPage.bind(this);
		this.openFeedbackForm = this.openFeedbackForm.bind(this);
		this.openOfferForm = this.openOfferForm.bind(this);
		this.openLanguageSelector = this.openLanguageSelector.bind(this);
		this.toggleDeleteAccountBox = this.toggleDeleteAccountBox.bind(this);
		this.showEditProfile = this.showEditProfile.bind(this);
	}

	UserActions() {
		let auth0 = this.props.auth0;

		return (
			<div>
				<TableViewHeader title={localizations.settings.profile} />
				<TableViewCell
					label={localizations.settings.edit_profile}
					onClick={this.showEditProfile}
				/>
				<TableViewCell
					label={localizations.settings.delete_profile}
					onClick={this.toggleDeleteAccountBox}
				/>

				<div className="paddedContent">
					<button
						style={{ marginTop: "50px" }}
						id="outline"
						onClick={() => auth0.logout({ returnTo: window.location.origin })}
					>
						{localizations.settings.logout}
					</button>
				</div>
			</div>
		);
	}

	Content() {
		let auth0 = this.props.auth0;

		return (
			<div>
				{auth0.isAuthenticated === true ? (
					this.UserActions()
				) : (
					<div className="paddedContent">
						<button
							onClick={() =>
								auth0.loginWithRedirect({
									ui_locales: "de",
								})
							}
						>
							{localizations.usage_reminder.button_login}
						</button>
					</div>
				)}
			</div>
		);
	}

	showConsultationPage() {
		window.open(CONSULTATION_URL, "_blank");
	}

	openFeedbackForm() {
		this.props.history.push("/feedback");
	}

	openOfferForm() {
		this.props.history.push("/offer");
	}

	openLanguageSelector() {
		this.props.history.push("/pickLanguage");
	}

	toggleDeleteAccountBox() {
		this.setState({ showDeleteAccountBox: !this.state.showDeleteAccountBox });
	}

	showEditProfile() {
		this.props.history.push("/profile/edit");
	}

	render() {
		let auth0 = this.props.auth0;

		return (
			<div className="content">
				<NavBar
					title={localizations.settings.title}
					rightBarButtonItemTitle={
						HIDE_LANGUAGE_SELECT
							? null
							: LanguageManager.getCountryLanguageString()
					}
					rightBarButtonItemAction={
						HIDE_LANGUAGE_SELECT ? null : this.openLanguageSelector
					}
				/>

				{auth0.isLoading ? <LoadingView /> : this.Content()}

				{this.state.showDeleteAccountBox && (
					<DeleteAccountBox closeFunction={this.toggleDeleteAccountBox} />
				)}
			</div>
		);
	}
}

export default withAuth0(SettingsView);
