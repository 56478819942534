import { useState, useEffect, useCallback } from "react";
import Localizer from "../Localization/Localizer";
let localizations = Localizer.getLocalizations();

function SlideCard(props) {
	const [isFavorite, setFavorite] = useState(false);

	const setFavoriteStatus = useCallback(() => {
		var favorites = props.favorites;

		setFavorite(
			favorites.find((element) => {
				return element.content._uid === props.item.content._uid;
			})
		);
	}, [props.favorites, props.item.content._uid]);

	useEffect(() => {
		setFavoriteStatus();
	}, [props.favorites, setFavoriteStatus]);

	function toggleFavorite() {
		var favorites = JSON.parse(localStorage.getItem("favorites")) || [];

		let index = favorites.findIndex(
			(obj) => obj.content._uid === props.item.content._uid
		);

		// Check if item is favorite or not.
		if (index === -1) {
			favorites.push(props.item);
			setFavorite(true);
		} else {
			favorites.splice(index, 1);
			setFavorite(false);
		}

		// Store favorites in local storage.
		localStorage.setItem("favorites", JSON.stringify(favorites));
	}

	const dividerBackgroundColor = {borderColor: props.mainGroup.content.color_second}
	const lightTextColor = props.mainGroup ? props.mainGroup.content.color_second : "#880"

	return (
		<div
			className="card"
			style={{
				minHeight: window.innerHeight < 640 ? 240 : 340,
				backgroundColor: props.backgroundColor,
			}}
		>
			<div className="card-leftSide">
				<div>
					<div className="card-inner-leftSide">

						{props.item.content.danger_pictogram !== null && (
							<div>
								<img
									className="pictogram"
									alt=""
									src={props.item.content.danger_pictogram.content.src.filename}
								/>
							</div>
						)}
						<p className="cardTitle">
							{props.item.content.code} <span style={{color: lightTextColor}}>{localizations.card.lkg} {props.storageClass.name}</span>
						</p>
						<div className="star-position">
							<img
								className="pictogram"
								alt=""
								src={isFavorite ? "/favorite_selected.svg" : "/favorite.svg"}
								onClick={toggleFavorite}
							/>
						</div>
					</div>
					<div className="cardSmallBoldTexSubTitle">
						<p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>
							{props.item.content.group
								? props.item.content.statement
								: localizations.card.keine_hauptgruppe}
						</p>
						<p
							style={{
								margin: 0,
								padding: 0,
								opacity: 0.7,
								fontWeight: "bold",
								color: lightTextColor
							}}
						>
							{props.item.content.additional_protective_measures ?? ""}
						</p>
					</div>
				</div>
			</div>

			<div className="card-rightSide" style={{ paddingBottom: window.innerHeight < 640 ? 10 : 20 ,paddingTop: window.innerHeight < 640 ? 6 : 16}}>
				<div className="card-inner-rightSide">
					<div className="card-inner-left-rightSide">
						<div style={{ display: "flex" }}>
							<p className="cardSmallText" style={{ flex: 1 }}>
								{localizations.card.lagerung_aus_lagern}
							</p>

							<p className="cardSmallBoldText">
								{props.item.content.static_outside_warehouse_max ?? ""}
							</p>
						</div>

						<div className="card-hr" style={{borderTopColor: dividerBackgroundColor, marginBottom: window.innerHeight < 640 ? 2 : 4 , marginTop: window.innerHeight < 640 ? 2 : 4 }} />

						<div style={{ display: "flex" }}>
							<p className="cardSmallText" style={{ flex: 1 }}>
								{localizations.card.lagerung_im_gefahrenstoffschrank}
							</p>
							<p className="cardSmallBoldText">
								{" "}
								{props.item.content.inside_locker_max ?? ""}
							</p>
						</div>

						<div className="card-hr" style={{borderTopColor: dividerBackgroundColor}} />

						<div style={{ display: "flex" }}>
							<p className="cardSmallText" style={{ flex: 1 }}>
								{localizations.card.lagerung_ab}
							</p>
							<p className="cardSmallBoldText">
								{" "}
								{props.item.content.inside_warehouse_starting ?? ""}
							</p>
						</div>

						<div className="card-hr" style={{borderTopColor: dividerBackgroundColor}} />

						<div style={{ display: "flex" }}>
							<p className="cardSmallText" style={{ flex: 1 }}>
								{localizations.card.anf_luftwechsel}
							</p>
							<p className="cardSmallBoldTextRight">
								{props.item.content.air_change_rate ?? ""}
							</p>
						</div>

						<div className="card-hr" style={{borderTopColor: dividerBackgroundColor}} />

						<div style={{ display: "flex" }}>
							<p className="cardSmallText" style={{ flex: 1 }}>
								{localizations.card.anf_brandschutz}
							</p>
							<p className="cardSmallBoldTextRight">
								{!props.item.content.fire_protection ? (
									<img
										src="/icon_xmark-circle-fill--dark.svg"
										className={"checked_icon"}
										alt=""
									/>
								) : (
									<img
										src="/icon_checkmark-circle-fill--dark.svg"
										className={"checked_icon"}
										alt=""
									/>
								)}
							</p>
						</div>

						<div className="card-hr" style={{borderTopColor: dividerBackgroundColor}} />

						<div style={{ display: "flex" }}>
							<p className="cardSmallText" style={{ flex: 1 }}>
								{localizations.card.anf_ex}
							</p>
							<p className="cardSmallBoldTextRight">
								{!props.item.content.ex_protection ? (
									<img
										src="/icon_xmark-circle-fill--dark.svg"
										className={"checked_icon"}
										alt=""
									/>
								) : (
									<img
										src="/icon_checkmark-circle-fill--dark.svg"
										className={"checked_icon"}
										alt=""
									/>
								)}
							</p>
						</div>
					</div>
				</div>
				<div className="card-inner-right-rightSide">
					<p
						className="cardSmallBoldText"
						style={{ fontWeight: "normal", opacity: 0.75, color: lightTextColor}}
					>
						{localizations.card.info}
					</p>
					<button
						onClick={() =>
							props.toggleCardDetails(
								props.item,
								props.storageClass,
								props.hSentenceDetails
							)
						}
						style={{ margin: "0" }}
						id="outline-card"
					>
						{localizations.card.btn_details}
					</button>
				</div>
			</div>
		</div>
	);
}

export default SlideCard;
