import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import InfoView from "./InfoView/InfoView";
import SettingsView from "./SettingsView/SettingsView";
import ProfileForm from "./Forms/ProfileForm";
import Main from "./Main";
import LanguagePicker from "./LanguagePicker/LanguagePicker";
import FeedbackForm from "./Forms/FeedbackForm";
import OfferForm from "./Forms/OfferForm";
import InfoDetailView from "./Reusable Views/InfoDetailView";
import TabBar from "./TabBar/TabBar";
import LaunchView from "./LaunchView";
import UsageCountReminderBox from "./Reusable Views/UsageCountReminderBox";

import { DISABLE_LIMITED_USAGE } from "./data/Constants";
import { withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import LoadingView from "./Reusable Views/LoadingView";
import NotFound from "./Reusable Views/NotFound";
import axios from "axios";

class App extends React.Component {
	state = {
		showUsageReminderBox: true,
	};

	constructor(props) {
		super(props);

		this.closeUsageCountReminderBox = this.closeUsageCountReminderBox.bind(
			this
		);
		this.isLaunchRoute = this.isLaunchRoute.bind(this);
	}

	componentDidMount() {
		this.increaseUsageCount();

		axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				//alert(localizations.general.general_error);

				return Promise.reject(error);
			}
		);
	}

	// Increase count in a cookie to keep track of the number of times a user has visited the app.
	increaseUsageCount() {
		// This is a debug option to temporarily disable limited usage. This is false in production and can be set in Constants.js
		if (DISABLE_LIMITED_USAGE) {
		//	return;
		}

		let usedCount = parseInt(localStorage.getItem("AppUsageCount") ?? 0, 10);
		localStorage.setItem("AppUsageCount", usedCount + 1);
	}

	closeUsageCountReminderBox() {
		this.setState({ showUsageReminderBox: false });
	}

	isLaunchRoute() {
		return (
			this.props.location.pathname === "/" ||
			this.props.location.pathname.includes("/launch")
		);
	}

	UsageReminderBox() {
		let auth0 = this.props.auth0;

		// Only display usage reminder box when:
		// 1. User is not logged in
		// 2. User is not at the launch screen
		// 3. User has not closed the box

		return (
			<div>
				{!auth0.isAuthenticated &&
					this.state.showUsageReminderBox === true &&
					!this.isLaunchRoute() && (
						<UsageCountReminderBox
							closeFunction={this.closeUsageCountReminderBox}
						/>
					)}
			</div>
		);
	}

	render() {
		let auth0 = this.props.auth0;

		if (auth0.isLoading) {
			return <LoadingView />;
		}

		return (
			<div>
				<Switch>
					<Route exact path="/" component={LaunchView} />

					<Route exact path="/info" component={InfoView} />
					<Route exact path="/info/viewer" component={InfoDetailView} />
					<Route exact path="/info/viewer/:id" component={InfoDetailView} />

					<Route exact path="/matrix" component={Main} />

					<Route exact path="/settings" component={SettingsView} />
					<Route exact path="/profile/edit" component={ProfileForm} />

					<Route exact path="/feedback" component={FeedbackForm} />
					<Route exact path="/offer" component={OfferForm} />

					<Route exact path="/pickLanguage" component={LanguagePicker} />
					<Route exact path="/launch/pickLanguage" component={LanguagePicker} />

					<Route exact path="*" component={NotFound} />
				</Switch>

				{!this.isLaunchRoute() && <TabBar />}

				{this.UsageReminderBox()}
			</div>
		);
	}
}

export default withAuth0(withRouter(App));
