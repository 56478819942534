import localization from "./Localization.json";
import React from "react";
import LocalizedStrings from "react-localization";


class Localizer extends React.Component {
	static getLocalizations() {
		let strings = new LocalizedStrings(localization);
		strings.setLanguage(localStorage.getItem("AppLanguage") ?? "DE");

		return strings;
	}
}

export default Localizer;
