import React from "react";
import NavBar from "../NavBar/NavBar";
import Localizer from "../Localization/Localizer";
import {CONSULTATION_URL} from "../data/Constants";
import StoryblokRenderer from "../Reusable Views/StoryblokRenderer";
import { render } from "storyblok-rich-text-react-renderer";
import OfferForm from "../Forms/OfferForm";
import Modal from "../Reusable Views/Modal/Modal";

let localizations = Localizer.getLocalizations();

class CardDetails extends React.Component {
	constructor(props) {
		super(props);

		this.state= {showOfferForm: false};
		this.close = this.close.bind(this);
		this.showConsultationPage = this.showConsultationPage.bind(this);
		this.showRecommendedProducts = this.showRecommendedProducts.bind(this);
		this.toggleOfferForm = this.toggleOfferForm.bind(this);
	}

	close() {
		this.props.closeHandler();
	}

	showConsultationPage() {
		window.open(CONSULTATION_URL, "_blank");
	}

	showRecommendedProducts() {
		this.props.history.push("/offer");
		//window.open(this.props.item.content.shop_url.url, "_blank");
	}

	storyblokInfos() {
		var components = [];
		for (var component of this.props.item.content
			.remarks_staticOutsideWarehouseMax.content) {
			//if (component.type === "paragraph") {
				if (typeof component.content != "undefined") {
					components.push(
						render({
							type: "doc",
							content: component.content,
						})
					);

					components.push(<br />);
				}
			//}
		}

		return components;
	}

	toggleOfferForm() {
		const {showOfferForm} = this.state;
		this.setState({showOfferForm: !showOfferForm})
	}

	render() {
		const {showOfferForm} = this.state;

		return (
			<div className="content">
				<NavBar
					title="Details"
					rightBarButtonItemTitle={localizations.general.close}
					rightBarButtonItemAction={this.close}
				/>
				<div className="paddedContent">
					<div className="card-leftSide card-leftSideDark">
						<div>
							<div className="card-inner-leftSide">
								{this.props.item.content.danger_pictogram !== null && (
									<div> 
									<img
										className="pictogram"
										alt=""
										src={this.props.item.content.danger_pictogram.content.src_negative.filename}
									/>
									</div>
								)}

								<p className="cardTitle">
									{this.props.item.content.code} <span>{localizations.card.lkg} {this.props.storageClass.name}</span>
								</p>
							</div>
							<div className="cardSmallBoldTexSubTitle">
								<p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>
									{this.props.item.content.group
										? this.props.item.content.statement
										: localizations.card.keine_hauptgruppe}
								</p>
								<p
									style={{
										margin: 0,
										padding: 0,
										opacity: 0.7,
										fontWeight: "bold",
									}}
								>
									{this.props.item.content.additional_protective_measures ?? ""}
								</p>
							</div>
						</div>
					</div>


					{this.storyblokInfos()}

					<button onClick={this.showConsultationPage}>{localizations.settings.consultation}</button>
					<button
						onClick={this.toggleOfferForm}
						id="outline"
						style={{ marginBottom: "20px" }}
					>
						{localizations.settings.offer}
					</button>

					<StoryblokRenderer
						content={this.props.hSentenceDetails.stories[0].content.contents}
					/>
				</div>
				{showOfferForm && (
					<Modal>
						<OfferForm showCancelButton={true} cancelHandler={this.toggleOfferForm} />
					</Modal>
				)}
			</div>
		);
	}
}

export default CardDetails;

/*
<TableViewHeader title="Basis-Informationen" />

	<TableViewCell
		name="cell"
		label={
			"Lagerung außerhalb von Lagern ab " +
			this.props.item.content.static_outside_warehouse_max
		}
		showChevron={false}
	/>
	<TableViewCell
		name="cell"
		label={
			"Lagerung im Gefahrenschank ab " +
			this.props.item.content.inside_locker_max
		}
		showChevron={false}
	/>
	<TableViewCell
		name="cell"
		label={
			"Lagerung ab " + this.props.item.content.inside_warehouse_starting
		}
		showChevron={false}
	/>
	<TableViewCell
		name="cell"
		label="Lagerklasse"
		detail={this.props.storageClass.name}
		showChevron={false}
	/>
	<TableViewCell
		name="cell"
		label="Lageranforderung EX"
		detail={this.props.item.content.ex_protection ? "Ja" : "Nein"}
		showChevron={false}
	/>
	<TableViewCell
		name="cell"
		label="Lageranforderung Brandschutz "
		detail={this.props.item.content.fire_protection ? "Ja" : "Nein"}
		showChevron={false}
	/>
	<TableViewCell
		name="cell"
		label="Lageranforderung Luftwechsel"
		detail={this.props.item.content.air_change_rate}
		showChevron={false}
	/>*/
