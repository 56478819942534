import React, { useState } from 'react';
import styled from "@emotion/styled";
import { Spring } from "react-spring/renderprops";
import { withGesture } from "react-with-gesture";
import SlideCard from "./SlideCard";
import { useGesture } from 'react-use-gesture'

const SlideContainer = styled.div`
	position: absolute;
	height: 70%;
	top: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	transform-origin: 50% 50%;
	max-width: 380px;
`;

function Slide({
	content,
	mainGroup,
	storageClass,
	hSentenceDetails,
	toggleCardDetails,
	favorites,
	offsetRadius,
	index,
	animationConfig,
	moveSlide,
	delta,
	down,
	up,
}) {
	const offsetFromMiddle = index - offsetRadius;
	const totalPresentables = 2 * offsetRadius + 1;
	const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));


	const [isWheeling, setIsWheeling] = useState(false);


	const translateYoffset =
		5 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
	let translateY = -50;



	if (offsetRadius !== 0) {
		if (index === 0) {
			translateY = 0;
		} else if (index === totalPresentables - 1) {
			translateY = -100;
		}
	}

	if (offsetFromMiddle === 0 && down) {

		translateY += delta[1] / (offsetRadius + 1);
		if (translateY > -40) {
			moveSlide(-1);
		}
		if (translateY < -60) {
			moveSlide(1);
		}
	}
	if (offsetFromMiddle > 0) {
		translateY += translateYoffset;
	} else if (offsetFromMiddle < 0) {
		translateY -= translateYoffset;
	}

	let wheelMovement = (d) => {

	if (typeof(d) != "undefined" && (d === -1 || d === 1) && isWheeling === false && offsetFromMiddle === 0 ) {
		setIsWheeling(true)

		// console.log("direction: "+ d)
		setTimeout(()=>{setIsWheeling(false);}, 1000);
		 	moveSlide(Math.round(d));
	}

	


	}

	const bg = [];
	bg[25] = mainGroup ? mainGroup.content.color_level4 : "pink";
	bg[50] = mainGroup ? mainGroup.content.color_level3 : "pink";
	bg[75] = mainGroup ? mainGroup.content.color_level2 : "pink";
	bg[100] = mainGroup ? mainGroup.content.color : "pink";


  const bind = useGesture({
	onWheel:  ({direction: [,d],}) => {wheelMovement(d)},
  })

	return (
		<Spring
			to={{
				transform: `translateX(0%) translateY(${translateY}%) scale(${
					offsetFromMiddle === 0 ? 1 : distanceFactor * 1.1
				})`,
				top: `${
					offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 50) / offsetRadius
				}%`,

			}}
			config={animationConfig}
		>
			{(style) => (
				<SlideContainer 
				{...bind()} 
					style={{
						...style,
						zIndex: distanceFactor * 100
					}}
				>
					<SlideCard

						onClick={() => moveSlide(offsetFromMiddle)}
						item={content}
						backgroundColor={bg[distanceFactor*100]}
						mainGroup={mainGroup}
						storageClass={storageClass}
						hSentenceDetails={hSentenceDetails}
						toggleCardDetails={toggleCardDetails}
						key={content.slug}
						favorites={favorites}
					/>
				</SlideContainer>
			)}
		</Spring>
	);
}

export default withGesture()(Slide);
