import React from "react";
import NavBar from "../NavBar/NavBar";
import TableViewCell from "../TableView/TableViewCell";
import TableViewHeader from "../TableView/TableViewHeader";
import Localizer from "../Localization/Localizer";
import axios from "axios";
import {
	STORYBLOK_BASE_URL,
	STORYBLOK_TOKEN
} from "../data/Constants";
import LanguageManager from "../LanguagePicker/LanguageManager";
import { HIDE_LANGUAGE_SELECT } from "../data/Constants";

let localizations = Localizer.getLocalizations();

class InfoView extends React.Component {
	state = {
		items: [],
	};

	constructor(props) {
		super(props);

		this.showDetails = this.showDetails.bind(this);
		this.openLanguageSelector = this.openLanguageSelector.bind(this);
		this.openFeedbackForm = this.openFeedbackForm.bind(this);
	}

	componentDidMount() {
		this.loadContent();
	}

	loadContent() {
		axios
			.get(
				STORYBLOK_BASE_URL +
					"apps/quantity-checker/de_de/navigations/?version=published&token=" +
					STORYBLOK_TOKEN +
					"&cv=" +
					Date.now()
			)
			.then((result) => {
				const items = result.data.story.content.items;

				console.log(items);

				this.setState({ items, isLoading: false });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ isLoading: false });
				}
			});
	}

	openLanguageSelector() {
		this.props.history.push("/pickLanguage");
	}

	openFeedbackForm() {
		this.props.history.push("/feedback");
	}

	showDetails(item) {
		// Storyblok sub-URLs look like this "harzardous-goods/de_de/regulations/overview-regulations"
		// We assume that external links like imprint contain http:// and should be opened directly instead of trying to render the storyblok item.
		let url = item.link.cached_url;

		if (url.includes("http")) {
			window.open(url, "_blank");
		} else if (item.is_app_route === true) {
			this.props.history.push({
				pathname: url,
				state: null,
			});
		} else {

			this.props.history.push({
				pathname: "/info/viewer",
				state: { storyblok_url: url,title: item.caption, },
			});
		}
	}

	ItemList() {
		// Renders the list of items from the storyblok. When item.group is true, a header is displayed.
		// Next, we use the item.uid to identify the cell that is showing the feedback form, as that one is the only one that does not link to a storyblok url or an external url, but should route to the React Feedback form instead!

		return (
			<div>
				{this.state.items.map((item, index) =>
					item.group ? (
						<TableViewHeader key={item.caption} title={item.caption} />
					) : item._uid === "f156df02-a7d3-4692-a105-4784edc2012f" ? (
						<TableViewCell
							key={item.caption}
							label={item.caption}
							onClick={this.openFeedbackForm}
						/>
					) : (
						<TableViewCell
							key={item.caption}
							label={item.caption}
							onClick={() => this.showDetails(item)}
						/>
					)
				)}
			</div>
		);
	}

	render() {
		return (
			<div className="content">
				<NavBar
					title={localizations.info.info_title}
					rightBarButtonItemTitle={
						HIDE_LANGUAGE_SELECT
							? null
							: LanguageManager.getCountryLanguageString()
					}
					rightBarButtonItemAction={
						HIDE_LANGUAGE_SELECT ? null : this.openLanguageSelector
					}
				/>

				{this.ItemList()}
			</div>
		);
	}
}

export default InfoView;
