import React from "react";
import axios from "axios";
import { STORYBLOK_BASE_URL, STORYBLOK_TOKEN } from "../data/Constants";
import LoadingView from "../Reusable Views/LoadingView";
import PropTypes from "prop-types";
import StoryblokRenderer from "./StoryblokRenderer";
import Localizer from "../Localization/Localizer";

let localizations = Localizer.getLocalizations();

class StoryblokView extends React.Component {
	state = {
		isLoading: true,
		content: null,
		error: false,
	};

	componentDidMount() {
		this.loadContent(this.props.url);
	}

	loadContent(url) {
		axios
			.get(
				STORYBLOK_BASE_URL +
					url +
					"?version=published&token=" +
					STORYBLOK_TOKEN +
					"&cv=" +
					Date.now()
			)
			.then((result) => {
				console.log(result);
				if (result.data.story.content.component === "app_page") {
					const content = result.data.story.content.contents;
					this.setState({ content, isLoading: false });
				} else if (
					result.data.story.content.component === "app_hazard_class_list"
				) {
					const content = result.data.story.content;
					this.setState({ content, isLoading: false });
				} else {
					this.setState({ error: true });
				}
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ error: true, isLoading: false });
				}
			});
	}

	render() {
		return (
			<div className="paddedContent">
				{this.state.content === null ? (
					this.state.error ? (
						<p>{localizations.general.general_error}</p>
					) : (
						<LoadingView />
					)
				) : (
					<StoryblokRenderer content={this.state.content} />
				)}
			</div>
		);
	}
}

StoryblokView.propTypes = {
	url: PropTypes.string.isRequired,
};

export default StoryblokView;
