import React, { Component } from "react";
import VerticalCarousel from "./StackView/VerticalCarousel";
import { config } from "react-spring";
import NavBar from "./NavBar/NavBar";
import Localizer from "./Localization/Localizer";
import LanguageManager from "./LanguagePicker/LanguageManager";
import { HIDE_LANGUAGE_SELECT } from "./data/Constants";
import "./StackView/StackView.css";
import "./MatrixView/MatrixGrid.css";
import axios from "axios";
import { STORYBLOK_TOKEN } from "./data/Constants";
import StackFilter from "./StackView/StackFilter";
import CardDetails from "./StackView/CardDetails";
import FavoritesView from "./StackView/FavoritesView";
import Modal from "./Reusable Views/Modal/Modal";
import LoadingView from "./Reusable Views/LoadingView";
import {isMobile} from 'react-device-detect';
let localizations = Localizer.getLocalizations();

class Main extends Component {
	state = {
		items: [],
		maingroups: [],
		filteredItems: [],
		favorites: [],
		selectedItem: null,
		searchString: "",
		goToSlide: 0,
		offsetRadius: 3,
		showNavigation: false,
		config: config.gentle,
		showStackFilter: false,
		showCardDetails: false,
		showFavorites: false,
		isLoadingHSentences: true,
		isLoadingHSentenceDetails: true,
		isLoadingMainGroups: true,
		isLoadingStorageClasses: true
	};

	constructor(props) {
		super(props);

		this.clearSearchField = this.clearSearchField.bind(this);
		this.handleSearchField = this.handleSearchField.bind(this);
		this.toggleStackFilter = this.toggleStackFilter.bind(this);
		this.toggleCardDetails = this.toggleCardDetails.bind(this);
		this.toggleFavoritesView = this.toggleFavoritesView.bind(this);
		this.showFavorite = this.showFavorite.bind(this);
	}

	componentDidMount() {
		this.loadHSentences();
		this.loadMainGroups();
		this.loadStorageClasses();
		this.loadHSentenceDetails();
		this.loadAllMainGroups();
		this.fetchFavorites();
	}

	openLanguageSelector() {
		this.props.history.push("/pickLanguage");
	}

	loadHSentences() {
		axios
			.get(
				"https://api.storyblok.com/v1/cdn/stories?starts_with=apps/quantity-checker/de_de/data/h-sentences/&per_page=100&resolve_relations=danger_pictogram,group&version=published&token=" +
				STORYBLOK_TOKEN +
				"&cv=" +
				Date.now()
			)
			.then((result) => {
				let items = result.data.stories;

				//console.log(items);

				this.setState({ items: items, isLoadingHSentences: false });

				this.applyFilter();
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ isLoadingHSentences: false });
				}
			});
	}

	loadAllMainGroups() {
		axios
			.get(
				"https://api.storyblok.com/v1/cdn/stories?starts_with=apps/quantity-checker/de_de/data/groups&resolve_relations=maingroup&version=published&token=" +
				STORYBLOK_TOKEN +
				"&cv=" +
				Date.now()
			)
			.then((result) => {
				let items = result.data;
				console.log("itemsitemsitemsitems",items);
				this.setState({maingroups: items})
			});
	}

	loadHSentenceDetails() {
		axios
			.get(
				"https://api.storyblok.com/v1/cdn/stories?starts_with=apps/quantity-checker/de_de/contents/h-sentence-details&version=published&token=" +
				STORYBLOK_TOKEN +
				"&cv=" +
				Date.now()
			)
			.then((result) => {
				let items = result.data;

				this.setState({
					hSentenceDetails: items,
					isLoadingHSentenceDetails: false,
				});

				this.applyFilter();
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ isLoadingHSentenceDetails: false });
				}
			});
	}

	getFavorites() {
		var favoritesString = localStorage.getItem("Favorites") ?? "";
		var favorites = favoritesString.split(",");

		this.setState({ favorites: favorites });
	}

	applyDefaultFilters() {
		if (localStorage.getItem("DefaultFiltersSet")) {
			return;
		}

		var filters = {};
		for (var item of this.state.mainGroups) {
			filters[item.uuid] = {
				name: item.content.title,
				active: true,
			};
		}

		var savedFilters = [];
		for (var key of Object.keys(filters)) {
			if (filters[key].active) {
				savedFilters.push(key);
			}
		}
		localStorage.setItem("StackFilters", savedFilters.join(","));
		localStorage.setItem("DefaultFiltersSet", true);
	}

	applyFilter() {
		var savedFiltersString = localStorage.getItem("StackFilters") ?? "";
		var savedFilters = savedFiltersString.split(",");

		var filteredItems = [];
		for (var item of this.state.items) {
			if (item.content.group !== null) {
				if (!item.content.only_pro) {
					let maingroup = item.content.group.content.maingroup;

					if (savedFilters.includes(maingroup)) {
						filteredItems.push(item);
					}
				}
			}
		}

		filteredItems = filteredItems.sort((a,b) => a.content.code.toLowerCase().localeCompare(b.content.code.toLowerCase())).sort((a,b) => a.content.group.content.maingroup.toLowerCase().localeCompare(b.content.group.content.maingroup.toLowerCase()))
		this.setState({ filteredItems: filteredItems});
	}

	findMainGroupByUUID(array, uuid) {
		return array.find((element) => {
			return element.uuid === uuid;
		});
	}

	loadMainGroups() {
		axios
			.get(
				"https://api.storyblok.com/v1/cdn/stories?starts_with=apps/quantity-checker/de_de/data/maingroups&per_page=100&resolve_relations=danger_pictogram,group&version=published&token=" +
				STORYBLOK_TOKEN +
				"&cv=" +
				Date.now()
			)
			.then((result) => {
				const mainGroups = result.data.stories;

				//console.log(mainGroups);

				this.setState({ mainGroups, isLoadingMainGroups: false });
				this.applyDefaultFilters();
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ isLoadingMainGroups: false });
				}
			});
	}

	loadStorageClasses() {
		axios
			.get(
				"https://api.storyblok.com/v1/cdn/stories?starts_with=apps/quantity-checker/de_de/data/storageclasses&per_page=100&version=published&token=" +
				STORYBLOK_TOKEN +
				"&cv=" +
				Date.now()
			)
			.then((result) => {
				const storageClasses = result.data.stories;

				//console.log(storageClasses);

				this.setState({ storageClasses, isLoadingStorageClasses: false });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ isLoadingStorageClasses: false });
				}
			});
	}

	clearSearchField() {
		this.setState({
			searchString: "",
		});

		this.filterStack("");
	}

	handleSearchField(event) {
		const value = event.target.value;
		this.setState({
			searchString: value,
		});

		this.filterStack(value);
	}

	filterStack(searchString) {

		var savedFiltersString = localStorage.getItem("StackFilters") ?? "";
		var savedFilters = savedFiltersString.split(",");

		var filteredItems = [];
		for (var item of this.state.items) {
			if (item.content.group !== null) {
				if (!item.content.only_pro) {
					let maingroup = item.content.group.content.maingroup;

					if (savedFilters.includes(maingroup)) {
						filteredItems.push(item);
					}
				}
			}
		}

		filteredItems = filteredItems.sort((a,b) => a.content.code.toLowerCase().localeCompare(b.content.code.toLowerCase())).sort((a,b) => a.content.group.content.maingroup.toLowerCase().localeCompare(b.content.group.content.maingroup.toLowerCase()))

		let results = filteredItems.filter((x) => x.content.code.toLowerCase().includes(searchString.toLowerCase()));

		//console.log(results);

		this.setState({ filteredItems: results });
	}

	toggleStackFilter() {
		if (this.state.showStackFilter) {
			this.applyFilter();
		}

		this.setState({ showStackFilter: !this.state.showStackFilter });
	}

	toggleCardDetails(item, storageClass) {
		this.setState({
			showCardDetails: !this.state.showCardDetails,
			selectedItem: item,
			selectedStorageClass: storageClass,
		});
	}

	toggleFavoritesView() {
		if (this.state.showFavorites) {
			this.fetchFavorites();
		}

		this.setState({ showFavorites: !this.state.showFavorites });
	}

	fetchFavorites() {
		var favorites = JSON.parse(localStorage.getItem("favorites")) || [];
		this.setState({ favorites: favorites });
	}

	showFavorite(uuid) {
		let index = this.state.filteredItems.findIndex(
			(obj) => obj.content._uid === uuid
		);

		this.setState({ goTo: index });

		this.toggleFavoritesView();
	}

	Content() {
		return (
			<div style={{position: "fixed", width: "100%"}}>
				<div className="paddedContent">
					<div class="stack-topRow">
						<div class="favoritesButton" onClick={this.toggleFavoritesView} style={{backgroundSize: window.innerHeight < 600 ? "20px 20px" : "28px 28px", height: window.innerHeight < 600 ? 30:50, minWidth: window.innerHeight < 600 ? 30:50}}/>

						<div className="stack-searchField"  style={{height: window.innerHeight < 600 ? 30:50}}>
							<img src="./search.svg" alt="" style={{ marginRight: "5px" }} />
							<input
								type="text"
								className="searchfield"
								placeholder={localizations.main.search_placeholder}
								value={this.state.searchString}
								style={{ height: window.innerHeight < 600 ? 30:50, width: window.innerWidth < 500 ? '80%' : window.innerWidth < 1000 ? '90%' : '95%'}}
								onChange={this.handleSearchField}
							/>
							{
								this.state.searchString.length > 0 ?
									<img onClick={this.clearSearchField} src="./clear.svg" alt="" style={{ width: 15, cursor: "pointer",marginRight: "5px" }} />
									:
									null
							}
						</div>
					</div>
					<p className="stack-hint" onClick={ () => this.props.history.push({
						pathname: "/info/viewer",
						state: { storyblok_url: "apps/quantity-checker/de_de/contents/hinweistext-allgemeine-schutzmassnahmen" },
					})}>
						{localizations.main.disclaimer} 
					</p>

					<p className="stack-hint">
					<b>{!isMobile ? localizations.general.use_to_scroll : null}</b>

					</p>
				</div>

				{this.state.filteredItems.length === 0 ? (
					<p className="stack-placeholder">
						{localizations.main.not_found}
					</p>
				) : (
					<div
						style={{
							position: "fixed",
							zIndex: 1,

							top: window.innerHeight < 652 ? "60%" : window.innerHeight < 710 ? "55%" : "53%",
							transform: window.innerHeight < 652 ? "translateY(-60%)" : window.innerHeight < 710 ? "translateY(-55%)" : "translateY(-50%)",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							width: "100vw",
							height: "40vh",
							margin: "0 auto",
							backgroundColor: "transparent",

						}}
					>
						<VerticalCarousel
							slides={this.state.filteredItems}
							mainGroups={this.state.mainGroups}
							favorites={this.state.favorites}
							storageClasses={this.state.storageClasses}
							hSentenceDetails={this.state.hSentenceDetails}
							toggleCardDetails={this.toggleCardDetails}
							offsetRadius={this.state.offsetRadius}
							showNavigation={this.state.showNavigation}
							animationConfig={this.state.config}
							goToSlide={this.state.goTo}
						/>
					</div>
				)}

				{this.state.showStackFilter && (
					<Modal>
						<StackFilter
							closeHandler={this.toggleStackFilter}
							mainGroups={this.state.mainGroups}
						/>
					</Modal>
				)}

				{this.state.showCardDetails && (
					<Modal>
						<CardDetails
							closeHandler={this.toggleCardDetails}
							item={this.state.selectedItem}
							storageClass={this.state.selectedStorageClass}
							hSentenceDetails={this.state.hSentenceDetails}
							history={this.props.history}
						/>
					</Modal>
				)}

				{this.state.showFavorites && (
					<Modal>
						<FavoritesView
							closeHandler={this.toggleFavoritesView}
							clickHandler={this.showFavorite}
							h_cards={this.state.filteredItems}
						/>
					</Modal>
				)}
			</div>
		);
	}


	render() {
		return (
			<div className="matrixGrid-wrapper" style={{paddingTop: window.innerHeight < 600 ? 52 : 64}}>
				<NavBar
					showLogo={true}
					leftBarButtonItemTitle="Filter"
					leftBarButtonItemAction={this.toggleStackFilter}
					rightBarButtonItemTitle={
						HIDE_LANGUAGE_SELECT
							? null
							: LanguageManager.getCountryLanguageString()
					}
					rightBarButtonItemAction={
						HIDE_LANGUAGE_SELECT ? null : this.openLanguageSelector
					}
				/>
				{!this.state.isLoadingHSentences &&
				!this.state.isLoadingMainGroups &&
				!this.state.isLoadingHSentenceDetails &&
				!this.state.isLoadingStorageClasses ? (
					this.Content()
				) : (
					<LoadingView />
				)}
			</div>
		);
	}
}

export default Main;
