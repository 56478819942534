import React from "react";
import styled from "@emotion/styled";
import Slide from "./Slide";
import PropTypes from "prop-types";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

const NavigationButtons = styled.div`
	position: relative;
	display: flex;

	height: 60px;
	margin: 0 auto;
	width: 20%;
	margin-top: 1rem;
	justify-content: space-between;
	z-index: 1000;
`;

const NavBtn = styled.div`
	background: white;
	padding: 15px;
	margin-bottom: 10px;
	border-radius: 3px;
`;

function mod(a, b) {
	return ((a % b) + b) % b;
}

class VerticalCarousel extends React.Component {
	state = {
		index: 0,
		goToSlide: null,
		prevPropsGoToSlide: 0,
		newSlide: false,
		run: 0,
		pause: false
	};

	componentDidMount = () => {
		document.addEventListener("keydown", (event) => {
			if (event.isComposing || event.keyCode === 229) {
				return;
			}
			if (event.keyCode === 38) {
				this.moveSlide(1);
			}
			if (event.keyCode === 40) {
				this.moveSlide(-1);
			}
		});

		// let run = 0;

		// document.addEventListener('wheel',  (event) => {
		// 	if(!run) {
		// 		run = 1;
		// 		if(event.deltaY < 0) {
		// 			this.moveSlide(-1);
		// 		}
		// 		if(event.deltaY >= 0) {
		// 			let slide_round = 5;
		// 			let deltaY = parseInt(event.deltaY);

		// 			if(deltaY > this.props.slides.length) {
		// 				deltaY = slide_round;
		// 			}

		// 			this.moveSlide(deltaY);
		// 		}

		// 		// let inter = this;
		// 		setTimeout(function(){ run = 0 }, 1200);
		// 	}
		// }, { passive: false });


	};



	componentDidUpdate() {
		this.handleGoToSlide();
	}

	static propTypes = {
		slides: PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.any,
				content: PropTypes.object,
			})
		).isRequired,
		goToSlide: PropTypes.number,
		showNavigation: PropTypes.bool,
		offsetRadius: PropTypes.number,
		animationConfig: PropTypes.object,
	};

	static defaultProps = {
		offsetRadius: 2,
		animationConfig: { tension: 120, friction: 14 },
	};

	modBySlidesLength = (index) => {
		return mod(index, this.props.slides.length);
	};

	moveSlide = (direction) => {
		if (this.state.pause === false) {

			//Nur Sliden wenn min 2 Slide verfügbar sind
			if (this.props.slides.length > 1) {
				this.setState({
					index: this.modBySlidesLength(this.state.index + direction),
					goToSlide: null,
				});
			}

			this.setState({
				pause: true
			});

			setTimeout(() => {
				this.setState({
					pause: false
				});
			}, 50);
		}
	};

	getShortestDirection(from: number, to: number): -1 | 0 | 1 {
		if (from > to) {
			if (from - to > this.props.slides.length - 1 - from + to) {
				return 1;
			} else return -1;
		} else if (to > from) {
			if (to - from > from + this.props.slides.length - 1 - to) {
				return -1;
			} else return 1;
		}
		return 0;
	}



	handleGoToSlide = () => {
		if (typeof this.props.goToSlide !== "number") {
			return;
		}

		const { index } = this.state;

		const goToSlide = mod(this.props.goToSlide, this.props.slides.length);

		if (goToSlide !== index) {
			let direction = this.getShortestDirection(index, goToSlide);
			const isFinished =
				this.modBySlidesLength(index + direction) === goToSlide;

			this.setState({
				index: this.modBySlidesLength(index + direction),
				newSlide: isFinished,
				goToSlide: isFinished ? null : goToSlide,
			});
		}
	};

	clampOffsetRadius(offsetRadius) {
		const { slides } = this.props;
		const upperBound = Math.floor((slides.length - 1) / 2);

		if (offsetRadius < 0) {
			return 0;
		}
		if (offsetRadius > upperBound) {
			return upperBound;
		}

		return offsetRadius;
	}

	getPresentableSlides() {
		const { slides } = this.props;
		const { index } = this.state;
		let { offsetRadius } = this.props;
		offsetRadius = this.clampOffsetRadius(offsetRadius);
		const presentableSlides = [];

		for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
			presentableSlides.push(slides[this.modBySlidesLength(index + i)]);
		}

		return presentableSlides;
	}

	/* Put all of these into one unified function if it turns out we always compare .uuid*/
	findMainGroupByUUID(array, uuid) {
		return array.find((element) => {
			return element.uuid === uuid;
		});
	}

	findStorageClassByUUID(array, uuid) {
		return array.find((element) => {
			return element.uuid === uuid;
		});
	}

	/* ---- */

	render() {
		const { animationConfig, offsetRadius, showNavigation } = this.props;

		let navigationButtons = null;
		if (showNavigation) {
			navigationButtons = (
				<NavigationButtons>
					<NavBtn onClick={() => this.moveSlide(1)}>&#8593;</NavBtn>
					<NavBtn onClick={() => this.moveSlide(-1)}>&#8595;</NavBtn>
				</NavigationButtons>
			);
		}
		return (
			<React.Fragment>
				<Wrapper>
					{this.getPresentableSlides().map((slide, presentableIndex) => (
						<Slide
							key={slide.slug}
							content={slide}
							mainGroup={this.findMainGroupByUUID(
								this.props.mainGroups,
								slide.content.group.content.maingroup
							)}
							storageClass={this.findStorageClassByUUID(
								this.props.storageClasses,
								slide.content.storageclass
							)}
							hSentenceDetails={this.props.hSentenceDetails}
							favorites={this.props.favorites}
							toggleCardDetails={this.props.toggleCardDetails}
							moveSlide={this.moveSlide}
							offsetRadius={this.clampOffsetRadius(offsetRadius)}
							index={presentableIndex}
							animationConfig={animationConfig}
							goToSlide={this.props.goToSlide}
						/>
					))}
				</Wrapper>
				{navigationButtons}
			</React.Fragment>
		);
	}
}

export default VerticalCarousel;
