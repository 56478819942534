import "./NavBar.css";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

function NavBar(props) {
	let history = useHistory();

	function goBack() {
		history.goBack();
	}

	return (
		<div className="navBar">
			{props.backButtonTitle == null ? (
				<div
					className="navBarItem"
					id="left"
					onClick={props.leftBarButtonItemAction}
				>
					{props.leftBarButtonItemTitle}
				</div>
			) : (
				<div className="navBarItem" id="back" onClick={goBack}>
					<div className="navBarItem-chevron" />
					{props.backButtonTitle}
				</div>
			)}

			{props.showLogo ? (
				<img src="./denios_navbar_logo.svg" alt="Denios Logo" />
			) : (
				<div className="navBarTitle">{props.title}</div>
			)}

			<div
				className="navBarItem"
				id={props.disableRightBarButtonItem ? "disabled" : "right"}
				onClick={
					!props.disableRightBarButtonItem
						? props.rightBarButtonItemAction
						: null
				}
			>
				{props.rightBarButtonItemTitle}
			</div>
		</div>
	);
}

NavBar.propTypes = {
	showLogo: PropTypes.bool, // When set, an image will be displayed instead of the title string.
	title: PropTypes.string, // Title in the middle of the navigation bar.
	backButtonTitle: PropTypes.string, // If set, a back button is shown with this title string. The left bar button item property will be ignored.
	leftBarButtonItemTitle: PropTypes.string, // (optional) title of the left button.
	rightBarButtonItemTitle: PropTypes.string, // (optional) title of the right button.
	leftBarButtonItemAction: PropTypes.func, // (optional) function that should be called when tapping or clicking the left button.
	rightBarButtonItemAction: PropTypes.func, // (optional) function that should be called when tapping or clicking the right button.
	disableRightBarButtonItem: PropTypes.bool, // disable the onClick event for the right button if true.
};

export default NavBar;
