import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import {
	AUTH0_BASE_URL,
	AUTH0_CLIENT_ID,
	AUTH0_REDIRECT_URI,
} from "./data/Constants";

ReactDOM.render(
	<BrowserRouter>
		<Auth0Provider
			domain={AUTH0_BASE_URL}
			clientId={AUTH0_CLIENT_ID}
			redirectUri={AUTH0_REDIRECT_URI}
			useRefreshTokens={true}
			cacheLocation="localstorage"
			//audience={"https://" + AUTH0_BASE_URL + "/api/v2/"}
			//scope="read:current_user update:current_user_metadata given_name"
			scope="openid profile email delete:user read:current_user update:current_user_metadata"
			audience="https://denios-hazmat-api/"
		>
			<App />
		</Auth0Provider>
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
