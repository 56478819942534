import Localizer from "../Localization/Localizer";
import { MAX_FREE_USAGE } from "../data/Constants";

import { useAuth0 } from "@auth0/auth0-react";
import ModalBox from "../Reusable Views/ModalBox/ModalBox";

function UsageCountReminderBox(props) {
	let localizations = Localizer.getLocalizations();
	const { loginWithRedirect } = useAuth0();

	function remainingFreeUsageCount() {
		// Returns the max free usage (as defined in Constants.js) minus the already used count.
		// If zero, the user can't use the app without an account any more.

		let usedCount = localStorage.getItem("AppUsageCount") ?? 0;

		if (usedCount >= MAX_FREE_USAGE) {
			return 0;
		}

		return MAX_FREE_USAGE - usedCount;
	}

	function closeBox() {
		props.closeFunction();
	}

	function ModalContent() {
		return (
			<div>
				<p className="modalBox-text">{localizations.usage_reminder.text}</p>

				<button
					onClick={() =>
						loginWithRedirect({
							ui_locales: "de",
						})
					}
				>
					{localizations.usage_reminder.button_login}
				</button>
				<button onClick={closeBox} disabled={remainingFreeUsageCount() <= 0}>
					{localizations.formatString(localizations.usage_reminder.button, {
						count: remainingFreeUsageCount(),
					})}
				</button>
			</div>
		);
	}

	return (
		<ModalBox
			title={localizations.usage_reminder.title}
			closeFunction={closeBox}
		>
			{ModalContent()}
		</ModalBox>
	);
}

export default UsageCountReminderBox;
