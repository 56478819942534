import { render } from "storyblok-rich-text-react-renderer";
import Localizer from "../Localization/Localizer";

let localizations = Localizer.getLocalizations();

function StoryblokRenderer(props) {
	// Storyblok items with a body can be rendered by using storyblok-rich-text-react-renderer.
	// We use a custom img for app_image and build a table for the component app_table. Everything else is ignored.

	function TextBlock(item) {
		return <div key={item._uid}>{render(item.body)}</div>;
	}

	function ImageBlock(item) {
		if(item.link != null) {
			if(item.link.url !== "" && item.link.url !== null) {
				return (
					<a href={item.link.url} rel="noreferrer" target={"_blank"}>
						<img
							key={item._uid}
							className="fullWidth"
							src={item.image.filename}
							alt=""
						/>
					</a>
				);
			}
		}
		return (
			<img
				key={item._uid}
				className="fullWidth"
				src={item.image.filename}
				alt=""
			/>
		);
	}

	function TableBlock(item) {
		var rows = [];

		let table = item.table;

		if (table.thead) {
			var values = [];
			for (var headColumn of table.thead) {
				values.push(<th key={headColumn._uid}>{headColumn.value}</th>);
			}
			rows.push(
				<thead key={"head" + item._uid}>
					<tr key={rows.length + item._uid}>{values}</tr>
				</thead>
			);
		}

		var bodyData = [];
		for (var row of table.tbody) {
			var rowData = [];
			for (var bodyColumn of row.body) {
				rowData.push(<td key={bodyColumn._uid}>{bodyColumn.value}</td>);
			}
			bodyData.push(rowData);
			rows.push(
				<tbody key={"body" + row._uid}>
					<tr key={row._uid}>{rowData}</tr>
				</tbody>
			);
		}

		return <table key={item._uid}>{rows}</table>;
	}

	function HazardClass(component) {
		var head = [
			<tr key="head">
				<th key="classes">{localizations.hazard_list.class}</th>
				<th key="desc">{localizations.hazard_list.description}</th>
			</tr>,
		];

		var rows = [];

		var rowNum = 0;
		for (var row of component.items) {
			const description = row.name + "\n" + row.description;
			rows.push(
				<tr key={"body" + rowNum}>
					<td key={row.classId}>{row.classId}</td>
					<td key={description + rowNum}>{description}</td>
				</tr>
			);
			rowNum += 1;
		}
		return (
			<div>
				<p className="hazard_list_title">{localizations.hazard_list.title}</p>
				<p className="hazard_list_text">{localizations.hazard_list.text}</p>

				<table>
					<thead>{head}</thead>
					<tbody>{rows}</tbody>
				</table>
			</div>
		);
	}

	if (props.content.component === "app_hazard_class_list") {
		return HazardClass(props.content);
	}

	return (
		<div>
			{props.content.map((item) =>
				item.component === "app_richtext"
					? TextBlock(item)
					: item.component === "app_table"
					? TableBlock(item)
					: item.component === "app_image" && ImageBlock(item)
			)}
		</div>
	);
}

export default StoryblokRenderer;
