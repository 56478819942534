import React from "react";


class LanguageManager extends React.Component {
	static getCountryLanguageString() {
		let country = localStorage.getItem("AppCountry") ?? "DE";
		let language = localStorage.getItem("AppLanguage") ?? "DE"; // As of v1.0 the app only supports one language, so "AppLanguage" is not used anywhere yet, this will always return "DE".

		return "§" + country + " / " + language;
	}
}

export default LanguageManager;
