import "./TabBar.css";
import { useHistory } from "react-router-dom";
import Localizer from "../Localization/Localizer";

function TabBar() {
	let localizations = Localizer.getLocalizations();
	let history = useHistory();

	function showInfoView() {
		history.push("/info");
	}

	function showMatrixView() {
		history.push("/matrix");
	}

	function showSettingsView() {
		history.push("/settings");
	}

	function currentPageIs(route) {
		return history.location.pathname.includes(route);
	}

	return (
		<div className="tabBar">
			<div
				className="tabBarItem"
				id={currentPageIs("info") ? "selected" : null}
				onClick={showInfoView}
			>
				<div
					className="tabBarItem-image"
					id={currentPageIs("info") ? "infoSelected" : "info"}
				/>
				{localizations.info.info_title}
			</div>

			<div
				className="tabBarItem"
				id={currentPageIs("matrix") ? "selected" : null}
				onClick={showMatrixView}
			>
				<div
					className="tabBarItem-image"
					id={currentPageIs("matrix") ? "matrixSelected" : "matrix"}
				/>
				{localizations.matrix.title}
			</div>

			<div
				className="tabBarItem"
				id={currentPageIs("settings") ? "selected" : null}
				onClick={showSettingsView}
			>
				<div
					className="tabBarItem-image"
					id={currentPageIs("settings") ? "settingsSelected" : "settings"}
				/>
				{localizations.settings.title}
			</div>
		</div>
	);
}

export default TabBar;
