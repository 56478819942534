import React from "react";
import NavBar from "../NavBar/NavBar";
import Localizer from "../Localization/Localizer";
import TableViewHeader from "../TableView/TableViewHeader";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

let localizations = Localizer.getLocalizations();

class FavoritesView extends React.Component {
	state = {
		favorites: [],
		deleteListe: [],
		showEdit: false
	};

	constructor(props) {
		super(props);

		this.save = this.save.bind(this);
		this.edit = this.edit.bind(this);
		this.deleteAll = this.deleteAll.bind(this);
		this.changeDelete = this.changeDelete.bind(this);
		this.showMsg = this.showMsg.bind(this);
	}

	componentDidMount() {
		NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
		var favorites = JSON.parse(localStorage.getItem("favorites")) || [];
		this.setState({ favorites: favorites });
	}

	deleteFavorite(index) {
		var favorites = JSON.parse(localStorage.getItem("favorites")) || [];
		favorites.splice(index, 1);
		// Store favorites in local storage.
		localStorage.setItem("favorites", JSON.stringify(favorites));
		this.setState({ favorites: favorites });
	}

	save() {
		this.props.closeHandler();
	}

	edit() {
		const {showEdit} = this.state;
		this.setState({showEdit: !showEdit})
	}

	changeDelete(index) {
		if(this.state.deleteListe.indexOf(index) !== -1) {
			this.state.deleteListe.splice(index, 1);
		} else{
			this.state.deleteListe.push(index);
		}

		this.setState({deleteListe: this.state.deleteListe});
	}

	deleteAll() {
		const {deleteListe} = this.state;
		var favorites = JSON.parse(localStorage.getItem("favorites")) || [];

		if(deleteListe.length > 0) {
			deleteListe.map(item =>
				favorites.splice(item, 1)
			);
		}

		// Store favorites in local storage.
		localStorage.setItem("favorites", JSON.stringify(favorites));
		this.setState({ favorites: favorites });
	}

	showMsg() {
		NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
		NotificationManager.info("", localizations.favorit.not_visible);
	}

	render() {
		const {showEdit} = this.state;

		const inHCards = (item) => {
			return this.props.h_cards.findIndex( itemy => itemy.uuid === item.uuid) !== -1 ? true : false;
		}

		const childRender = (item, index) => {
			const isActive = inHCards(item);

			return <div className={(!isActive) ? showEdit ? 'tableViewCell-favorites' : 'tableViewCell-favorites notactive' : 'tableViewCell-favorites'}>
				<div className="tableViewCell-favorites-container">
					{showEdit ? <p style={{padding:0, marginTop: 7, marginRight: 5}}><input type={"checkbox"} onClick={() => this.changeDelete(index)}/></p> : null}

					<img
						className="tableViewCell-image"
						alt=""
						src={
							item.content.danger_pictogram.content.src_negative.filename
						}
					/>

					<div onClick={() => (!isActive && !showEdit) ? this.showMsg() : this.props.clickHandler(item.content._uid)}>
						<span style={{fontSize:15}}>{item.content.code}</span>

						<p className="tableViewCell-detail">
							<small>{item.content.group.name}</small>
						</p>
					</div>
				</div>

				{
					showEdit ? null :
						<div
							className="cell-deleteButton"
							onClick={() => this.deleteFavorite(index)}
						>
							{localizations.general.delete}
						</div>
				}

			</div>
		}

		return (
			<div className="content">
				<NavBar
					title={localizations.general.favorit}
					backButtonTitle={
						null
					}
					leftBarButtonItemAction={ showEdit ? this.deleteAll : this.edit}
					leftBarButtonItemTitle={showEdit ? localizations.general.done : localizations.favorit.edit}

					rightBarButtonItemTitle={localizations.general.close}
					rightBarButtonItemAction={() => this.props.closeHandler()}
				/>

				<TableViewHeader title={localizations.general.noted} />

				{
					this.state.favorites.length === 0 ? <p style={{textAlign: 'center'}}>{localizations.favorit.not_found}</p>: null
				}
				{
					this.state.favorites.map((item, index) => (
						childRender(item, index)
					))
				}

				{
					showEdit ?

						<div className="paddedContent" style={{marginTop: 50}}>
							<button
								onClick={() => this.deleteAll()
								}
							>
								{localizations.general.delete}
							</button>
						</div>
						:
						null
				}
				<NotificationContainer/>
			</div>
		);
	}
}

export default FavoritesView;
