import React from "react";
import NavBar from "../NavBar/NavBar";
import { withRouter } from "react-router-dom";
import StoryblokView from "./StoryblokView";
import Localizer from "../Localization/Localizer";

let localizations = Localizer.getLocalizations();

class InfoDetailView extends React.Component {

	render() {

		return (
			<div className="content">
				<NavBar
					title={this.props.location.state.title ? this.props.location.state.title : ""}
					backButtonTitle={
						this.props.showCancelButton ? null : localizations.general.back
					}
					leftBarButtonItemTitle={
						this.props.showCancelButton ? localizations.general.cancel : null
					}
					leftBarButtonItemAction={
						this.props.showCancelButton ? this.props.cancelHandler : null
					}
				/>

				<StoryblokView
					url={
						this.props.location.state
							? this.props.location.state.storyblok_url
							: this.props.url
					}
				/>
			</div>
		);
	}
}

export default withRouter(InfoDetailView);
