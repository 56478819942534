import "./TableView.css";
import PropTypes from "prop-types";
import Switch from "react-ios-switch";

function TableViewToggleCell(props) {
	return (
		<div className="tableViewCell" id="text">
			{props.label}
			<Switch
				name={props.name}
				checked={props.checked}
				onChange={(e) => props.onChange(e, props.name)}
				onColor="#97BF0D"
			/>
		</div>
	);
}

TableViewToggleCell.defaultProps = {
	label: "Default Cell Label",
	text: "",
};

TableViewToggleCell.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	name: PropTypes.string.isRequired, // name of the field to handle changes.
	onChange: PropTypes.func.isRequired, // function to call when the text changes.
	checked: PropTypes.bool, // the value of the toggle.
};

export default TableViewToggleCell;
