import NavBar from "../NavBar/NavBar";

function NotFound(props) {
	return (
		<div>
			<NavBar showLogo={true} />

			<div className="paddedContent" style={{ marginTop: "200px" }}>
				<p
					style={{ textAlign: "center", fontSize: "20px", fontWeight: "bold" }}
				>
					Seite nicht gefunden.
				</p>
			</div>
		</div>
	);
}

export default NotFound;
