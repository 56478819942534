import React from "react";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import TableViewHeader from "../TableView/TableViewHeader";
import TableViewTextCell from "../TableView/TableViewTextCell";
import TableViewDropdownCell from "../TableView/TableViewDropdownCell";
import { REACT_APP_MAIL_SERVICE_BASE_URL } from "../data/Constants";
import LoadingView from "../Reusable Views/LoadingView";
import { withAuth0 } from "@auth0/auth0-react";
import Localizer from "../Localization/Localizer";

let localizations = Localizer.getLocalizations();

class ProfileForm extends React.Component {
	state = {
		gender: "-",
		first_name: "",
		last_name: "",
		zip: "",
		company: "",
		email: "",
		newsletter: true,
		isLoading: true,
		isSending: false,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
		this.sendForm = this.sendForm.bind(this);
	}

	componentDidMount() {
		this.loadUserMetadata();
	}

	async loadUserMetadata() {
		let auth0 = this.props.auth0;

		// Get an access token from auth0 and use it to load the user details.

		try {
			const accessToken = await auth0.getAccessTokenSilently();

			const userDetailsByIdUrl =
				"https://" +
				REACT_APP_MAIL_SERVICE_BASE_URL +
				"/api/v2/users/" +
				auth0.user.sub;

			const metadataResponse = await fetch(userDetailsByIdUrl, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			const { user_metadata } = await metadataResponse.json();
			console.log(user_metadata);
			let email = auth0.user.email ?? "";
			this.setState({
				isLoading: false,
				email: email,
				gender: user_metadata.gender ?? "-",
				first_name: user_metadata.first_name ?? "",
				last_name: user_metadata.last_name ?? "",
				zip: user_metadata.zip ?? "",
				company: user_metadata.company ?? "",
				newsletter: user_metadata.newsletter ?? false,
			});
		} catch (e) {
			console.log(e.message);
		}
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
		});
	}

	handleToggleChange(checked) {
		// Important: API expects a string value instead of a boolean.
		this.setState({
			newsletter: checked === true ? "true" : "false",
		});
	}

	async sendForm() {
		let auth0 = this.props.auth0;

		this.setState({ isSending: true });

		// Get an access token from auth0 and use it so patch the updated user data.

		try {
			const accessToken = await auth0.getAccessTokenSilently();

			axios.defaults.headers.common.Accept = "application/json";
			axios.defaults.headers.common.Authorization = "Bearer " + accessToken;

			let data = {
				user_metadata: {
					first_name: this.state.first_name,
					last_name: this.state.last_name,
					gender: this.state.gender,
					zip: this.state.zip,
					company: this.state.company,
					newsletter: this.state.newsletter,
				},
			};

			axios
				.patch(
					"https://" +
						REACT_APP_MAIL_SERVICE_BASE_URL +
						"/api/v2/users/" +
						auth0.user.sub,
					data
				)
				.then(() => {
					this.setState({ isSending: false });
					this.props.history.goBack();
				})
				.catch((error) => {
					console.log(error);
					this.setState({ isSending: false });
				});
		} catch (e) {
			console.log(e);
		}
	}

	GenderOptions() {
		return [
			<option key="-" value="-">
				{localizations.salutations.default}
			</option>,

			<option key="male" value="m">
				{localizations.salutations.m}
			</option>,
			<option key="female" value="w">
				{localizations.salutations.f}
			</option>,
			<option key="diverse" value="d">
				{localizations.salutations.d}
			</option>,
		];
	}

	Form() {
		return (
			<div>
				<TableViewHeader title={localizations.forms.personal_info} />
				<TableViewDropdownCell
					name="gender"
					label={localizations.forms.salutation}
					value={this.state.gender}
					items={this.GenderOptions()}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="first_name"
					label={localizations.forms.firstname}
					text={this.state.first_name}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="last_name"
					label={localizations.forms.lastname}
					text={this.state.last_name}
					onChange={this.handleChange}
				/>
				<TableViewHeader title={localizations.forms.email} />
				<TableViewTextCell
					name="email"
					label={localizations.forms.email}
					text={this.state.email}
					readonly
				/>

				<TableViewHeader title={localizations.forms.company_placeholder} />
				<TableViewTextCell
					name="company"
					label={localizations.forms.company}
					text={this.state.company}
					placeholder={localizations.forms.company_placeholder}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="zip"
					label={localizations.forms.zip}
					placeholder={localizations.forms.zip_placeholder}
					text={this.state.zip}
					onChange={this.handleChange}
				/>
			</div>
		);
	}

	render() {
		let auth0 = this.props.auth0;

		return (
			<div className="content">
				<NavBar
					title={localizations.forms.profile_edit}
					backButtonTitle={localizations.general.back}
					rightBarButtonItemTitle={localizations.general.save}
					rightBarButtonIsConfirm={true}
					rightBarButtonItemAction={this.sendForm}
					disableRightBarButtonItem={
						this.state.first_name.length === 0 ||
						this.state.last_name.length === 0
					}
				/>

				{this.state.isSending || auth0.isLoading || this.state.isLoading ? (
					<LoadingView />
				) : (
					this.Form()
				)}
			</div>
		);
	}
}

export default withAuth0(ProfileForm);
