import React from "react";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import TableViewHeader from "../TableView/TableViewHeader";
import TableViewTextCell from "../TableView/TableViewTextCell";
import {MAIL_SERVICE_URL, PRIVACY_URL, REACT_APP_MAIL_SERVICE_BASE_URL} from "../data/Constants";
import LoadingView from "../Reusable Views/LoadingView";
import TableViewMultilineCell from "../TableView/TableViewMultilineCell";
import Localizer from "../Localization/Localizer";
import { withAuth0 } from "@auth0/auth0-react";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

let localizations = Localizer.getLocalizations();

class OfferForm extends React.Component {
	state = {
		firstname: "",
		lastname: "",
		company: "",
		zip: "",
		email: "",
		phone: "",
		message: "",
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.sendForm = this.sendForm.bind(this);
		this.showPrivacyTerms = this.showPrivacyTerms.bind(this);
	}

	async loadUserMetadata() {
		let auth0 = this.props.auth0;

		// Get an access token from auth0 and use it to load the user details.

		try {
			const accessToken = await auth0.getAccessTokenSilently();

			const userDetailsByIdUrl =
				"https://" +
				REACT_APP_MAIL_SERVICE_BASE_URL +
				"/api/v2/users/" +
				auth0.user.sub;

			const metadataResponse = await fetch(userDetailsByIdUrl, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			const { user_metadata } = await metadataResponse.json();

			this.setState({
				firstname: user_metadata.first_name ?? "",
				lastname: user_metadata.last_name ?? "",
				zip: user_metadata.zip ?? "",
				company: user_metadata.company ?? "",
			});
		} catch (e) {
			console.log(e.message);
		}
	}

	componentDidMount() {
		NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
		let auth0 = this.props.auth0;

		if (auth0.isAuthenticated) {
			this.loadUserMetadata()
			let email = auth0.user.email ?? "";

			this.setState({
				email: email,
			});
		}
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
		});
	}

	sendForm() {
		NotificationManager.listNotify.forEach(n => NotificationManager.remove({ id: n.id }));
		this.setState({ isSending: true });

		axios
			.post(MAIL_SERVICE_URL, {
				formName: "Offer_Form",
				fields: [
					{
						fieldName: "firstname",
						fieldValue: this.state.firstname,
					},
					{
						fieldName: "lastname",
						fieldValue: this.state.lastname,
					},
					{
						fieldName: "company",
						fieldValue: this.state.company,
					},
					{
						fieldName: "zip",
						fieldValue: this.state.zip,
					},
					{
						fieldName: "email",
						fieldValue: this.state.email,
					},
					{
						fieldName: "phone",
						fieldValue: this.state.phone,
					},
					{
						fieldName: "message",
						fieldValue: this.state.message,
					},
				],
			})
			.then(() => {
				NotificationManager.success(localizations.forms.form_success_message, localizations.forms.form_success);
				this.setState({ isSending: false, firstname: "", lastname: "", company: "", email: "", message: "", });
				const history_v = this.props.history;
				setTimeout(function(){ history_v.goBack() },2000);
			})
			.catch((error) => {
				NotificationManager.error(localizations.forms.form_error_message, localizations.forms.form_error);
				this.setState({ isSending: false });
			});
	}

	showPrivacyTerms() {
		window.open(PRIVACY_URL, "_blank");
	}

	Form() {
		return (
			<div>
				<div className="paddedContent">
					<p className="smallTitle">{localizations.forms.offer_title}</p>
					<p className="text">{localizations.forms.offer_subtitle}</p>
				</div>

				<TableViewHeader title={localizations.forms.personal_info} />
				<TableViewTextCell
					name="firstname"
					label={localizations.forms.firstname}
					text={this.state.firstname}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="lastname"
					label={localizations.forms.lastname}
					text={this.state.lastname}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="company"
					label={localizations.forms.company}
					placeholder={localizations.forms.company_placeholder}
					text={this.state.company}
					onChange={this.handleChange}
				/>

				<TableViewTextCell
					name="zip"
					label={localizations.forms.zip}
					placeholder={localizations.forms.zip_placeholder}
					text={this.state.zip}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="email"
					label={localizations.forms.email}
					text={this.state.email}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="phone"
					label={localizations.forms.phone}
					placeholder={localizations.forms.phone_placeholder}
					text={this.state.phone}
					onChange={this.handleChange}
				/>
				<TableViewHeader title={localizations.forms.message} />
				<TableViewMultilineCell
					name="message"
					label={localizations.forms.offer_message_placeholder}
					text={this.state.message}
					onChange={this.handleChange}
				/>
				<div className="s">
					<button
						onClick={this.showPrivacyTerms}
						style={{ width: '100%', borderLeft: 0, borderRight: 0, paddingLeft: 20, marginTop: "50px", textAlign: 'left', fontSize: 12, fontWeight: 400 }}
						id="outline"

					>
						{localizations.forms.privacy}
						<img src={'/safari.svg'}  alt="" style={{width: 17, position: 'absolute',right: 10}} />
					</button>
				</div>
			</div>
		);
	}

	render() {

		return (
			<div className="content">
				<NavBar
					title={localizations.forms.offer}
					backButtonTitle={
						this.props.showCancelButton ? null : localizations.general.cancel
					}
					leftBarButtonItemAction={
						this.props.showCancelButton ? this.props.cancelHandler : null
					}
					leftBarButtonItemTitle={
						this.props.showCancelButton ? localizations.general.cancel : null
					}
					rightBarButtonItemTitle={localizations.general.send}
					rightBarButtonIsConfirm={true}
					rightBarButtonItemAction={(this.state.isSending) ? null : this.sendForm}
					disableRightBarButtonItem={
						this.state.firstname.length === 0 ||
						this.state.lastname.length === 0 ||
						this.state.email.length === 0 ||
						this.state.message.length === 0
					}
				/>

				{this.state.isSending ? <LoadingView /> : this.Form()}

				<NotificationContainer/>
			</div>
		);
	}
}

export default withAuth0(OfferForm);
