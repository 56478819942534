import React from "react";
import NavBar from "../NavBar/NavBar";
import TableViewCell from "../TableView/TableViewCell";
import TableViewHeader from "../TableView/TableViewHeader";
import TableViewFooter from "../TableView/TableViewFooter";
import Localizer from "../Localization/Localizer";


let localizations = Localizer.getLocalizations();

class LanguagePicker extends React.Component {
	state = {
		selectedCountry: localStorage.getItem("AppCountry") ?? "DE",
		selectedLanguage: "DE",
	};

	constructor(props) {
		super(props);

		this.save = this.save.bind(this);
	}

	save() {
		// TODO:
		// Probably needs more work for the language
		// changes to take effect when more languages than German
		// are supported! Also needs to take care of changing the
		// matrix when changing the country!

		this.props.history.goBack();
	}

	changeCountry(country) {
		localStorage.setItem("AppCountry", country);

		this.setState({ selectedCountry: country });
	}

	render() {
		return (
			<div className="content">
				<NavBar
					title={localizations.language_picker.country_language}
					rightBarButtonItemTitle={localizations.general.done}
					rightBarButtonItemAction={this.save}
				/>

				<TableViewHeader title={localizations.language_picker.country} />
				<TableViewCell
					label={localizations.language_picker.germany}
					onClick={() => this.changeCountry("DE")}
					showChevron={false}
					showCheckmark={this.state.selectedCountry === "DE"}
				/>

				<TableViewFooter title={localizations.language_picker.footer} />

				<TableViewHeader title={localizations.language_picker.language} />
				<TableViewCell
					label={localizations.language_picker.german}
					showChevron={false}
					showCheckmark={true}
				/>
			</div>
		);
	}
}

/*
<TableViewCell
label={"Österreich (AT)"}
onClick={() => this.changeCountry("AT")}
showChevron={false}
showCheckmark={this.state.selectedCountry === "AT"}
/>

<TableViewCell
label={"Schweiz (CH)"}
onClick={() => this.changeCountry("CH")}
showChevron={false}
showCheckmark={this.state.selectedCountry === "CH"}
/>
*/

export default LanguagePicker;
