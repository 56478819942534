import React from "react";
import NavBar from "./NavBar/NavBar";
import TableViewCell from "./TableView/TableViewCell";
import TableViewHeader from "./TableView/TableViewHeader";
import Localizer from "./Localization/Localizer";

import { HIDE_LANGUAGE_SELECT } from "./data/Constants";

let localizations = Localizer.getLocalizations();

class LaunchView extends React.Component {
	state = {
		selectedCountry: localStorage.getItem("AppCountry") ?? "DE",
		selectedLanguage: localStorage.getItem("AppLanguage") ?? "DE",
	};

	constructor(props) {
		super(props);

		this.next = this.next.bind(this);
		this.openLanguageSelector = this.openLanguageSelector.bind(this);
	}

	componentDidMount() {
		if (HIDE_LANGUAGE_SELECT) {
			this.props.history.push("/matrix");
			return;
		}

		// Present the matrix view when the app has already been used once and skip the launch screen.
		if (localStorage.getItem("AppLaunchScreenPresented")) {
			this.props.history.push("/matrix");
		}
	}

	render() {
		return (
			<div>
				<NavBar
					rightBarButtonItemTitle={localizations.general.next}
					rightBarButtonItemAction={this.next}
				/>

				<div className="launchLogo" />

				<div className="launchGradient">
					<p className="launchTitle">{localizations.general.app_name}</p>

					<div
						style={{
							position: "absolute",
							backgroundColor: "white",
							marginTop: "100px",
							width: "100%",
							paddingBottom: "30px",
						}}
					>
						<TableViewHeader title={localizations.language_picker.country_2} />
						<TableViewCell
							label={this.labelForSelectedCountry()}
							onClick={this.openLanguageSelector}
						/>

						<TableViewHeader title={localizations.language_picker.language} />
						<TableViewCell
							label={this.labelForSelectedLanguage()}
							onClick={this.openLanguageSelector}
						/>
					</div>
				</div>
			</div>
		);
	}

	labelForSelectedCountry() {
		switch (this.state.selectedCountry) {
			case "DE":
				return localizations.language_picker.germany;
			case "CH":
				return localizations.language_picker.switzerland;
			case "AT":
				return localizations.language_picker.austria;
			default:
				return localizations.language_picker.germany;
		}
	}

	labelForSelectedLanguage() {
		switch (this.state.selectedLanguage) {
			case "DE":
				return localizations.language_picker.german;
			default:
				return localizations.language_picker.german;
		}
	}

	next() {
		localStorage.setItem("AppLaunchScreenPresented", true);
		this.props.history.push("/matrix");
	}

	openLanguageSelector() {
		this.props.history.push("/launch/pickLanguage");
	}
}

export default LaunchView;
