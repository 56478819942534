import "./Modal.css";
import React from "react";

class Modal extends React.Component {

	constructor(props) {
		super(props);
		this.state= {scale: 0};
		this.myRef = React.createRef()
	}


	componentDidMount = () => {

	};

	render () {
		return (
			<div className="modal-overlay">
				<div className="modal" ref={this.myRef}>{this.props.children}</div>
			</div>
		);
	}
}

export default Modal;
