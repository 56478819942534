import React from "react";
import NavBar from "../NavBar/NavBar";
import Localizer from "../Localization/Localizer";

import TableViewToggleCell from "../TableView/TableViewToggleCell";

let localizations = Localizer.getLocalizations();

class StackFilter extends React.Component {
	state = {
		filters: [],
	};

	constructor(props) {
		super(props);

		this.save = this.save.bind(this);
		this.handleToggleChange = this.handleToggleChange.bind(this);
	}

	componentDidMount() {
		var savedFiltersString = localStorage.getItem("StackFilters") ?? "";
		var savedFilters = savedFiltersString.split(",");

		var filters = [];


		for (var item of this.props.mainGroups) {

			if (!item.content.only_pro) {
				filters[item.uuid] = {
					uuid: item.uuid,
					name: item.content.title,
					active: savedFilters.includes(item.uuid),
					sorting: item.content.sorting,
				};
			}
		}

		filters = Object.values(filters).sort((a,b ) => a.sorting.localeCompare(b.sorting));

		this.setState({ filters: filters });
	}

	save() {
		this.props.closeHandler();
	}

	handleToggleChange(checked, name) {
		var filters = this.state.filters;
		filters[name].active = checked;

		this.setState({
			filters: filters,
		});

		var savedFilters = [];
		for (var item of Object.keys(filters)) {
			if (filters[item].active) {
				savedFilters.push(filters[item].uuid);
			}
		}

		localStorage.setItem("StackFilters", savedFilters.join(","));
	}

	render() {
		return (
			<div className="content">
				<NavBar
					title="Filter"
					rightBarButtonItemTitle={localizations.general.done}
					rightBarButtonItemAction={this.save}
				/>

				{Object.keys(this.state.filters).map((item) => (
					<TableViewToggleCell
						key={item}
						name={item}
						label={this.state.filters[item].name}
						checked={this.state.filters[item].active}
						onChange={this.handleToggleChange}
					/>
				))}
			</div>
		);
	}
}

export default StackFilter;
