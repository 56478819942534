import "./TableView.css";
import PropTypes from "prop-types";

function TableViewCell(props) {
	return (
		<div
			className="tableViewCell"
			onClick={props.onClick}
			id={props.disabled ? "disabled" : null}
		>
			{props.label}

			<div className="tableViewCell-right">
				<p className="tableViewCell-detail">{props.detail}</p>
				{props.showChevron && (
					<div className="tableViewCell-chevron" id={props.chevronDirection} />
				)}
				{props.showCheckmark && <div className="tableViewCell-checkmark" />}
			</div>
		</div>
	);
}

TableViewCell.defaultProps = {
	label: "Default Cell Label",
	detail: "",
	showChevron: true,
	showCheckmark: false,
	chevronDirection: "right",
};

TableViewCell.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	action: PropTypes.func, // (optional) function that should be called when tapping or clicking the cell.
	showChevron: PropTypes.bool, // show chevron on the right side of the cell. Default is true.
	showCheckmark: PropTypes.bool, // show a checkmark on the right side of the cell. Default is false.
	disabled: PropTypes.bool, // when disabled, the cell does not have a hover effect. Actions can still be called.
	chevronDirection: PropTypes.string, // can be set to right, up, down
};

export default TableViewCell;
